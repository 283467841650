import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import Form from "~components/organisms/form/form";
import usePageData from "~hooks/use_page_data/use_page_data";
import { STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";

const HEADING_MARGIN = { marginTop: "md" };

export default function TemplateContactPage(props) {
  const { pageContext, story } = usePageData(props);
  const { subNavData, lang } = pageContext;
  const {
    form: formArray,
    heading,
    sectionSettings: sectionSettingsArray,
    subheading,
    ...rest
  } = story;

  const [form] = formArray;
  const [sectionSettings] = sectionSettingsArray;

  return (
    <TemplateGlobalEntry
      headerColor="blue"
      lang={lang}
      subNavData={subNavData}
      {...props}
    >
      <Section id="contact" sectionSettings={sectionSettings} {...rest}>
        <Contain>
          {heading && (
            <Heading
              {...rest}
              balanceText
              isCentered
              margin={HEADING_MARGIN}
              seoLevel={1}
              visualLevel={2}
            >
              {heading}
            </Heading>
          )}
          {subheading && (
            <Heading
              {...rest}
              balanceText
              isCentered
              seoLevel={2}
              visualLevel={5}
            >
              {subheading}
            </Heading>
          )}
          {form && <Form {...form} />}
        </Contain>
      </Section>
    </TemplateGlobalEntry>
  );
}

TemplateContactPage.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

TemplateContactPage.defaultProps = {
  pageContext: {
    subNavData: [],
  },
};
// TODO: get page specific metadata here
export const query = graphql`
  query TemplateContactPageQuery($id: String, $langRegex: String) {
    ...story
    ...global
  }
`;
